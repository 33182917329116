export const fetchPlayerSplitStats = async (
  playerId = 8479318,
  year = 20192020
) => {
  const PLAYER_DATA_URL = `https://statsapi.web.nhl.com/api/v1/people/${playerId}?expand=person.stats&stats=gameLog&season=${year}&site=en_nhlCA`

  const response = await fetch(PLAYER_DATA_URL)

  if (response.status === 200) {
    const data = await response.json()
    const stats = data.people[0].stats[0].splits

    return {
      stats: stats,
    }
  }
}
