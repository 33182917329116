import React, { useEffect, useState } from "react"
import moment from "moment"
import { teams } from "../../../api/hockeyTeams"
import {
  fetchPlayersOfTheWeek,
  fetchPlayerSplitStats,
} from "../../../api/hockeyPlayerOfTheWeek"
import { graphql } from "gatsby"

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts"

import Layout from "../../../components/layout"
import Intro from "../../../components/intro"

const HockeyPlayerOfTheWeekInvestigation = props => {
  const [stats, setStats] = useState([])
  const [statsArray, setStatsArray] = useState([])
  const [statList, setStatList] = useState([])


  const players = props.data.allPlayersOfTheWeek.edges
  // Last three in array should indicate most recent
  // Make Random
  const threeStars = players.slice(1).slice(-3)
  const votingDate = moment(threeStars[0].node.votingDate).format("MMM DD")
  const startOfWeek = moment(votingDate, 'MMM DD')
    .subtract({ days: 7 })
    .format("MMM DD")

  const [statType, setStatType] = useState(threeStars.map(item => {
    if (item.node.player.position !== 'G') {
      return 'points';
    } else {
      return 'saves'
    }
  }))

  const meta = {
    title: "NHL Player of the Week Investigation",
    subject: "Math",
    description: `Each week the NHL picks three stars. Below are the three stars from ${startOfWeek} to ${votingDate}. `,
    questions: [
      "Explain how each player stood out statistically over the past week.",
      "Which player deserves 1st star?",
      "Are we missing information that would allow us to make a better decision?"
    ],
    source: "Player data and Three Stars data is sourced from the NHL API.",
  }

  const playerStats = () => {
    return Promise.all(stats).then(values => {
      return values
    })
  }

  useEffect(() => {
    const playerDataPromise = threeStars.map((item, i) => {
      return fetchPlayerSplitStats(item.node.player.id).then(res => res)
    })
    setStats(playerDataPromise)
  }, [])

  useEffect(() => {
    playerStats().then(res => setStatsArray(res))
  }, [stats])

  useEffect(() => {
    if (statsArray.length > 0) {
      const stats = statsArray.map(item => {
        const list = item.stats[0].stat
        const keys = Object.keys(list)
        return keys
          .filter(item => !item.includes("time"))
          .filter(item => !item.includes("Time"))
          .filter(item => !item.includes("decision"))
      })
      setStatList(stats)
    }
  }, [statsArray])

  return (
    <Layout>
      <Intro data={meta} />
      <br />
      {threeStars.map((item, i) => {
        return (
          <div key={i} style={{ marginBottom: 50 }}>
            <div className="card" key={i}>
              <div className="card-content">
                <p>
                  {item.node.player.firstName} {item.node.player.lastName},{" "}
                  {item.node.player.position}, {item.node.team.fullName}
                </p>
                <div className="select">
                  <select
                    name="team"
                    id="team-select"
                    onChange={e =>
                      setStatType(() => [
                        ...statType.slice(0, i),
                        e.target.value,
                        ...statType.slice(i + 1),
                      ])
                    }
                  >
                    <option value={statType[i]}>{statType[i]}</option>
                    {statList.length > 0 &&
                      statList[i].map((item, i) => (
                        <option key={i} value={item}>{item}</option>
                      ))}
                  </select>
                </div>
                <br />
                <br />
                <br />
                {statsArray.map((item, index) => {
                  if (index === i) {
                    return (
                      <ResponsiveContainer key={index} width="95%" height={500}>
                        <BarChart
                          data={item.stats.slice().reverse()}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                          key={index}
                        >
                          <XAxis
                            dataKey="date"
                            tickFormatter={date => moment(date).format("MM/DD")}
                          />
                          <YAxis
                            allowDecimals={false}
                            label={{
                              value: statType[i],
                              angle: -90,
                              position: "insideLeft",
                            }}
                          />
                          <Tooltip />
                          <Bar dataKey={`stat.${statType[i]}`} fill="#253640" />
                        </BarChart>
                      </ResponsiveContainer>
                    )
                  }
                })}
              </div>
            </div>
          </div>
        )
      })}
    </Layout>
  )
}

export default HockeyPlayerOfTheWeekInvestigation

export const query = graphql`
  query PlayersOfTheWeekQuery {
    allPlayersOfTheWeek {
      edges {
        node {
          player {
            id
            firstName
            lastName
            position
          }
          team {
            id
            commonName
            fullName
            placeName
          }
          threeStarType {
            id
            description
            shortDescription
          }
          threeStarTypeId
          votingDate
        }
      }
    }
  }
`
