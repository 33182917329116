export const teams = [
  {
    name: "New Jersey Devils",
    id: 1,
  },
  {
    name: "New York Islanders",
    id: 2,
  },
  {
    name: "New York Rangers",
    id: 3,
  },
  {
    name: "Philadelphia Flyers",
    id: 4,
  },
  {
    name: "Pittsburgh Penguins",
    id: 5,
  },
  {
    name: "Boston Bruins",
    id: 6,
  },
  {
    name: "Buffalo Sabres",
    id: 7,
  },
  {
    name: "Montréal Canadiens",
    id: 8,
  },
  {
    name: "Ottawa Senators",
    id: 9,
  },
  {
    name: "Toronto Maple Leafs",
    id: 10,
  },
  {
    name: "Carolina Hurricanes",
    id: 12,
  },
  {
    name: "Florida Panthers",
    id: 13,
  },
  {
    name: "Tampa Bay Lightning",
    id: 14,
  },
  {
    name: "Washington Capitals",
    id: 15,
  },
  {
    name: "Chicago Blackhawks",
    id: 16,
  },
  {
    name: "Detroit Red Wings",
    id: 17,
  },
  {
    name: "Nashville Predators",
    id: 18,
  },
  {
    name: "St. Louis Blues",
    id: 19,
  },
  {
    name: "Calgary Flames",
    id: 20,
  },
  {
    name: "Colorado Avalanche",
    id: 21,
  },
  {
    name: "Edmonton Oilers",
    id: 22,
  },
  {
    name: "Vancouver Canucks",
    id: 23,
  },
  {
    name: "Anaheim Ducks",
    id: 24,
  },
  {
    name: "Dallas Stars",
    id: 25,
  },
  {
    name: "Los Angeles Kings",
    id: 26,
  },
  {
    name: "San Jose Sharks",
    id: 28,
  },
  {
    name: "Columbus Blue Jackets",
    id: 29,
  },
  {
    name: "Minnesota Wild",
    id: 30,
  },
  {
    name: "Winnipeg Jets",
    id: 52,
  },
  {
    name: "Arizona Coyotes",
    id: 53,
  },
  {
    name: "Vegas Golden Knights",
    id: 54,
  },
]
